import { reactive } from 'vue';
//import plz_energie from './plz_energie.json';
import plz_pumpe from './plz_pumpe.json';
import plz_nrw from './plz_nrw.json';
import plz_all from './plz_inhouse.json';
import plz_extern from './plz_42watt.json';

export const store = reactive({
    //url_nrw_plz: "https://www.vattenfall.de/energiewende",
    customer: {
        firstname: '',
        lastname: '',
        street: '',
        zip: '',
        city: '',
        mail: '',
        phone: '',
        agree: false
    },
    building: {
        zip: '',
        type: '',
        owner: '',
        monument: '',
        baujahr: '',
        renovation: '',
        wand: '',
        dach: '',
        heating_installation: '',
        roadmap: '',
    },
    message: '',
    energieberatungspaket: false,
    history: [],
    installation_alternative: false,
    uuid: '',
    current: 0,
    current_step: 0,
    loaded: false,
    plz_energie: plz_all,
    plz_pumpe: plz_pumpe,
    plz_nrw: [...plz_nrw],
    plz_extern: [...plz_extern],
    zipCodes: [...plz_all, ...plz_pumpe],
    success_zip_pumpe: false,
    success_zip_energie: false,
    success_zip_nrw: false,

    link_extern_plz: {
        text: "Zum Angebot von 42Watt",
        url: "https://www.42watt.de/landing/partner/vattenfall?utm_campaign=vattenfall_online&utm_source=partner&utm_medium=online",
        dashboard: "https://www.42watt.de",
        target: "_parent",
        crossdomain: false,
        urlparams: true,
    },

    link_nrw_plz: {
        text: "Zur kostenlosen Heizungs-Beratung",
        url: "https://www.vattenfall.de/energiewende",
        dashboard: "https://www.vattenfall.de/energiewende",
        target: "_parent",
        crossdomain: true,
        urlparams: false,
    },

    link_energieberatung: {
        text: "Energieberatung bestellen",
        url: "https://online.vattenfall.de/energieberatungspaket",
        dashboard: "https://online.vattenfall.de/energieberatungspaket",
        target: "_parent",
        crossdomain: true,
        urlparams: false,
    },

    link_energieberatung_mehrerfahren: {
        text: "Mehr erfahren",
        url: "https://www.vattenfall.de/waerme/energieberatung",
        dashboard: "https://www.vattenfall.de/waerme/energieberatung",
        target: "_parent",
        crossdomain: true,
        urlparams: false,
    },

    link_wpberatung: {
        text: "Kostenlose Beratung sichern",
        url: "https://frontend.vlink.com/?key=vshton",
        dashboard: "Wärmepumpen-Anfrage (https://frontend.vlink.com/?key=vshton)",
        target: "_self",
        crossdomain: true,
        urlparams: true,
    },

    link_vattenfallshop: {
        text: "Smart Heizen und Energie sparen (tink.de)",
        url: "https://vattenfall.tink.de/c/heizung",
        dashboard: "https://vattenfall.tink.de/c/heizung",
        target: "_blank",
        crossdomain: false,
        urlparams: false,
    },

    link_sunshine_plz: {
        text: "Zum Angebot von Sunshine",
        url: "https://ihrsanierungsfahrplan.de/",
        dashboard: "https://ihrsanierungsfahrplan.de/",
        urlparams: false,
        target: "_parent",
        crossdomain: false,
        //url: "https://vattenfall.ihrsanierungsfahrplan.de",
        //dashboard: "https://vattenfall.ihrsanierungsfahrplan.de",
    },


})

